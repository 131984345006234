const routes = [
  {
    path: '/senders',
    name: 'senders-list',
    component: () => import(/* webpackChunkName: "page/senders/list" */'@/views/pages/senders/List'),
    meta: {
      permissions: 'senders_read'
    }
  },
  {
    path: '/senders/new',
    name: 'senders-new',
    component: () => import(/* webpackChunkName: "page/senders/new" */'@/views/pages/senders/New'),
    meta: {
      permissions: 'senders_write'
    }
  },
  {
    path: '/senders/:id',
    name: 'senders-single',
    component: () => import(/* webpackChunkName: "page/senders/single" */'@/views/pages/senders/Single'),
    meta: {
      permissions: 'senders_read'
    }
  },
  {
    path: '/senders/:id/edit',
    name: 'senders-edit',
    component: () => import(/* webpackChunkName: "page/senders/edit" */'@/views/pages/senders/Edit'),
    meta: {
      permissions: 'senders_write'
    }
  }
]

export default routes
