import general from './general'
import auth from './auth'
import dashboard from './dashboard'
import campaignsLabels from './campaigns/labels'
import campaigns from './campaigns'
import feedbacks from './feedbacks'
import categoryGroups from './category-groups'
import contactListsCategories from './contact-lists/categories'
import contactLists from './contact-lists'
import templatesCategories from './templates/categories'
import templates from './templates'
import sendersCategories from './senders/categories'
import senders from './senders'
import subjectsCategories from './subjects/categories'
import subjects from './subjects'
import blacklists from './blacklists'
import badwordCategories from './badwords/categories'
import badwords from './badwords'
import offers from './offers'
import domains from './domains'
import companies from './companies'
import users from './users'
import apisProviders from './apis/providers'
import apis from './apis'

const routes = [
  ...general,
  ...auth,
  ...dashboard,
  ...campaignsLabels,
  ...campaigns,
  ...feedbacks,
  ...categoryGroups,
  ...contactListsCategories,
  ...contactLists,
  ...templatesCategories,
  ...templates,
  ...sendersCategories,
  ...senders,
  ...subjectsCategories,
  ...subjects,
  ...blacklists,
  ...badwordCategories,
  ...badwords,
  ...offers,
  ...domains,
  ...companies,
  ...users,
  ...apisProviders,
  ...apis
]

export default routes
