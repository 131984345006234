<template>
  <div class="box-section">
    <h4><slot/></h4>
  </div>
</template>

<script>
export default {
  name: 'BoxSection'
}
</script>

<style lang="scss">
.box-section {
  padding: 10px;
  margin: 10px -10px;
  border-top: 3px solid #d2d6de;
  border-bottom: 1px solid #f4f4f4;
  h4 {
    margin: 0;
  }
}
</style>
