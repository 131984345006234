<template>
  <column :xs="xs" :sm="sm" :md="md" :lg="lg">
    <strong>{{ title }}</strong>
    <p>
      <slot/>
    </p>
  </column>
</template>

<script>
export default {
  name: 'ColumnInfo',
  props: {
    title: { type: String, default: '' },
    xs: { type: Number, default: 12 },
    sm: { type: Number, default: 6 },
    md: { type: Number, default: 3 },
    lg: { type: Number, default: undefined }
  }
}
</script>
