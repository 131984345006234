const routes = [
  {
    path: '/templates/categories',
    name: 'templates-categories-list',
    component: () => import(/* webpackChunkName: "page/templates/categories/list" */'@/views/pages/templates/categories/List'),
    meta: {
      permissions: 'template_categories_read'
    }
  },
  {
    path: '/templates/categories/new',
    name: 'templates-categories-new',
    component: () => import(/* webpackChunkName: "page/templates/categories/new" */'@/views/pages/templates/categories/New'),
    meta: {
      permissions: 'template_categories_write'
    }
  },
  {
    path: '/templates/categories/:id',
    name: 'templates-categories-single',
    component: () => import(/* webpackChunkName: "page/templates/categories/single" */'@/views/pages/templates/categories/Single'),
    meta: {
      permissions: 'template_categories_read'
    }
  },
  {
    path: '/templates/categories/:id/edit',
    name: 'templates-categories-edit',
    component: () => import(/* webpackChunkName: "page/templates/categories/edit" */'@/views/pages/templates/categories/Edit'),
    meta: {
      permissions: 'template_categories_write'
    }
  }
]

export default routes
