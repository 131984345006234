export default {
  namespaced: true,

  state: {
    version: null,
    highlightedIds: {}
  },

  getters: {
    version (state) { return state.version }
  },

  mutations: {
    set_version (state, value) { state.version = value },
    add_highlightId (state, value) { state.highlightedIds[value] = true },
    remove_highlightId (state, value) { delete state.highlightedIds[value] }
  },

  actions: {
    addHighlightedId ({ state, commit }, id) {
      commit('add_highlightId', id)

      setTimeout(() => {
        commit('remove_highlightId', id)
      }, 60 * 1000)
    },
    checkHighlightedId ({ state, commit }, id) {
      const exists = !!state.highlightedIds[id]

      if (exists) commit('remove_highlightId', id)

      return exists
    }
  }
}
