import store from '@/modules/store'

const isLoggedIn = () => !!store.getters['auth/authenticated']

const checkRoute = (app, route) => {
  if (route.matched.some((r) => r.meta.guest)) {
    if (isLoggedIn()) return { name: 'index' }
  } else {
    if (!isLoggedIn()) {
      return {
        name: 'login',
        query: { nextUrl: route.fullPath }
      }
    }
  }

  if (route.matched.some((r) => !!r.meta.permissions)) {
    if (!app.$gates.hasAnyPermission(route.meta.permissions)) return { name: 'index' }
  }

  return null
}

const beforeEach = async (app) => {
  app.$router
    .beforeEach((to, from, next) => {
      const destination = checkRoute(app, to)

      destination ? next(destination) : next()
    })
}

const firstLoad = async (app) => {
  const destination = checkRoute(app, app.$route)

  if (destination) await app.$router.push(destination)
}

export const routerAddGuards = async (app) => {
  await beforeEach(app)
  await firstLoad(app)
}
