import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: {},
    roles: [],
    perms: []
  },

  getters: {
    authenticated (state) { return state.authenticated },
    user (state) { return state.user },
    roles (state) { return state.roles },
    perms (state) { return state.perms }
  },

  mutations: {
    set_authenticated (state, value) { state.authenticated = value },
    set_user (state, value) { state.user = value },
    set_roles (state, value) { state.roles = value },
    set_perms (state, value) { state.perms = value }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/auth/login', credentials)
        .catch((error) => { throw error })

      return dispatch('me')
    },
    async signOut ({ dispatch }) {
      await axios.post('/auth/logout')
        .catch((error) => { throw error })

      return dispatch('me')
    },
    me ({ commit, dispatch }) {
      return axios.get('/auth/me')
        .then((response) => {
          const {
            user, roles, perms,
            app_version: appVersion
          } = response.data

          commit('set_authenticated', true)
          commit('set_user', user)
          commit('set_roles', roles)
          commit('set_perms', perms)
          commit('app/set_version', appVersion, { root: true })
        })
        .catch(() => {
          dispatch('clean')
        })
    },
    clean ({ commit }) {
      commit('set_authenticated', false)
      commit('set_user', {})
      commit('set_roles', [])
      commit('set_perms', [])
      commit('app/set_version', null, { root: true })
    }
  }
}
