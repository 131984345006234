<template>
  <footer class="main-footer">
    <!-- To the right -->
    <div class="pull-right hidden-xs">
      <p>
        <badge title="Release Version">{{ version }}</badge>
        <badge title="Server Time" color="light-blue">{{ time }}</badge>
      </p>
    </div>
    <!-- Default to the left -->
    <strong>Copyright &copy; {{ date }}</strong>
  </footer>
</template>

<script>
import Badge from '@/views/components/simple/Badge'

export default {
  name: 'Footer',
  components: {
    Badge
  },
  computed: {
    version () { return this.$store.getters['app/version'] }
  },
  data () {
    return {
      date: new Date().getFullYear(),
      time: '00:00:00',
      interval: undefined
    }
  },
  beforeMount () {
    this.updateTime()
    this.interval = setInterval(() => { this.updateTime() }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    updateTime () {
      this.time = new Date().toLocaleTimeString('en-GB', {
        timeZone: 'America/New_York',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    }
  }
}
</script>
