const routes = [
  {
    path: '/category-groups',
    name: 'categoryGroups-list',
    component: () => import(/* webpackChunkName: "page/category-groups/list" */'@/views/pages/category-groups/List'),
    meta: {
      permissions: 'category_groups_read'
    }
  },
  {
    path: '/category-groups/new',
    name: 'categoryGroups-new',
    component: () => import(/* webpackChunkName: "page/category-groups/new" */'@/views/pages/category-groups/New'),
    meta: {
      permissions: 'category_groups_write'
    }
  },
  {
    path: '/category-groups/:id',
    name: 'categoryGroups-single',
    component: () => import(/* webpackChunkName: "page/category-groups/single" */'@/views/pages/category-groups/Single'),
    meta: {
      permissions: 'category_groups_read'
    }
  },
  {
    path: '/category-groups/:id/edit',
    name: 'categoryGroups-edit',
    component: () => import(/* webpackChunkName: "page/category-groups/edit" */'@/views/pages/category-groups/Edit'),
    meta: {
      permissions: 'category_groups_write'
    }
  }
]

export default routes
