<template>
  <section class="content">
    <router-view/>
  </section>
</template>

<script>
export default {
  name: 'LayoutGuest'
}
</script>
