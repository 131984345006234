const routes = [
  {
    path: '/companies',
    name: 'companies-list',
    component: () => import(/* webpackChunkName: "page/companies/list" */'@/views/pages/companies/List'),
    meta: {
      permissions: 'companies_read'
    }
  },
  {
    path: '/companies/new',
    name: 'companies-new',
    component: () => import(/* webpackChunkName: "page/companies/list" */'@/views/pages/companies/List'),
    meta: {
      permissions: 'companies_write'
    }
  },
  {
    path: '/companies/:id',
    name: 'companies-single',
    component: () => import(/* webpackChunkName: "page/companies/single" */'@/views/pages/companies/Single'),
    meta: {
      permissions: 'companies_read'
    }
  },
  {
    path: '/companies/:id/edit',
    name: 'companies-edit',
    component: () => import(/* webpackChunkName: "page/companies/single" */'@/views/pages/companies/Single'),
    meta: {
      permissions: 'companies_write'
    }
  }
]

export default routes
