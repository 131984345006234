<template>
  <div class="box" :class="boxClass">

    <div class="box-header" :class="boxHeaderClass">
      <h3 class="box-title">{{ title }}</h3>

      <div class="box-tools pull-right">
        <slot name="box-tools-right"/>

        <button type="button" class="btn btn-box-tool" title="Collapse" data-widget="collapse" @click="toggle">
          <i class="fa" :class="btnIcon"></i>
        </button>
      </div>
    </div>

    <slide-up-down :active="expanded">
      <div class="box-body with-border" v-if="$slots['pre-content']">
        <slot name="pre-content"/>
      </div>

      <div class="box-body" :class="{ 'with-border': $slots['post-content'] }">
        <slot name="content"/>
      </div>

      <div class="box-body" v-if="$slots['post-content']">
        <slot name="post-content"/>
      </div>
    </slide-up-down>

    <div class="box-footer" v-if="$slots.footer">
      <slot name="footer"/>
    </div>

    <div v-if="loading" class="overlay">
      <i class="fa fa-refresh fa-spin"></i>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    title: { type: String, default: 'Info' },
    color: { type: String, default: 'primary' },
    open: { type: Boolean, default: true },
    solid: { type: Boolean, default: false },
    border: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  computed: {
    boxClass () { return ['box-' + this.color, this.solid ? 'box-solid' : ''] },
    boxHeaderClass () { return (this.border || this.solid) ? 'with-border' : '' },
    btnIcon () { return this.expanded ? 'fa-minus' : 'fa-plus' }
  },
  data () {
    return {
      expanded: this.open
    }
  },
  watch: {
    open (val) { this.expanded = val }
  },
  methods: {
    toggle () { this.expanded = !this.expanded }
  }
}
</script>

<style lang="scss">
.box {
  .box-header {
    .box-tools {
      &.pull-right {
        a.btn {
          margin-right: 10px;
        }
      }
    }
  }
  .overlay > .fa {
    color: #333;
  }
}
</style>
