<template>
  <li :class="{ 'active': params.active, 'menu-open': params.expanded }">
    <a href="#" @click.prevent="toggle">
      <i class='fa' :class="params.icon"></i>
      <span>{{ params.title }}</span>
      <i class="fa fa-angle-left pull-right"></i>
    </a>
    <slide-up-down :active="params.expanded" tag="ul" class="treeview-menu">
      <template v-for="child in params.children">
        <router-link tag="li" :key="`menu-child-${child.routeName}`" :to="{ name: child.routeName }">
          <a>{{ child.title }}</a>
        </router-link>
      </template>
    </slide-up-down>
  </li>
</template>

<script>
export default {
  name: 'MenuItemParent',
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          icon: '',
          title: '',
          active: false,
          expanded: false,
          children: []
        }
      }
    }
  },
  methods: {
    toggle () { this.params.expanded = !this.params.expanded }
  }
}
</script>

<style lang="scss">
li ul.treeview-menu {
  display: block;
}
</style>
