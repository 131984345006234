const getChangedSelectVal = (oldValues, isMultiToSingle) => {
  if (isMultiToSingle) {
    if (Array.isArray(oldValues) && oldValues.length === 1) {
      return oldValues[0]
    }
  } else {
    if (Array.isArray(oldValues) && oldValues.length > 0) {
      return oldValues
    } else if (Object.isObject(oldValues)) {
      return [oldValues]
    }
  }
}

export default {
  getChangedSelectVal
}
