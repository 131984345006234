import Vue from 'vue'
import bootstrap from '@/modules/bootstrap'
import router from '@/modules/router'
import store from '@/modules/store'
import App from '@/App'
import '@/registerServiceWorker'

bootstrap()

const app = new Vue({
  store,
  router,
  render: (h) => h(App)
})

app.$mount('#app')
