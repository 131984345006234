const routes = [
  {
    path: '/campaigns/labels',
    name: 'campaigns-labels-list',
    component: () => import(/* webpackChunkName: "page/campaigns/labels/list" */'@/views/pages/campaigns/labels/List'),
    meta: {
      permissions: 'campaign_labels_read'
    }
  },
  {
    path: '/campaigns/labels/new',
    name: 'campaigns-labels-new',
    component: () => import(/* webpackChunkName: "page/campaigns/labels/new" */'@/views/pages/campaigns/labels/New'),
    meta: {
      permissions: 'campaign_labels_write'
    }
  },
  {
    path: '/campaigns/labels/:id',
    name: 'campaigns-labels-single',
    component: () => import(/* webpackChunkName: "page/campaigns/labels/single" */'@/views/pages/campaigns/labels/Single'),
    meta: {
      permissions: 'campaign_labels_read'
    }
  },
  {
    path: '/campaigns/labels/:id/edit',
    name: 'campaigns-labels-edit',
    component: () => import(/* webpackChunkName: "page/campaigns/labels/edit" */'@/views/pages/campaigns/labels/Edit'),
    meta: {
      permissions: 'campaign_labels_write'
    }
  }
]

export default routes
