const routes = [
  {
    path: '/domains',
    name: 'domains-list',
    component: () => import(/* webpackChunkName: "page/domains/list" */'@/views/pages/domains/List'),
    meta: {
      permissions: 'domains_read'
    }
  },
  {
    path: '/domains/new',
    name: 'domains-new',
    component: () => import(/* webpackChunkName: "page/domains/new" */'@/views/pages/domains/New'),
    meta: {
      permissions: 'domains_write'
    }
  },
  {
    path: '/domains/:id',
    name: 'domains-single',
    component: () => import(/* webpackChunkName: "page/domains/single" */'@/views/pages/domains/Single'),
    meta: {
      permissions: 'domains_read'
    }
  }
]

export default routes
