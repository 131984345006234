import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueGates from 'vue-gates'
import VueSweetalert2 from 'vue-sweetalert2'
import LoadScript from 'vue-plugin-load-script'
import PortalVue from 'portal-vue'
import helpers from '@/modules/helpers/index'

const initPlugins = () => {
  Vue.use(VueMeta)
  Vue.use(VueGates)
  Vue.use(VueSweetalert2)
  Vue.use(LoadScript)
  Vue.use(PortalVue)
  Vue.use(helpers)
}

export default initPlugins
