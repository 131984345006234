<template>
  <div id="app">
    <layout v-if="loaded"/>
    <h2 v-else class="app-preloader"
        style="position:absolute;width:100%;margin:10px 10px 0 0;text-align:center;font-weight:600;font-size:18px"
    >
      <i class="fa fa-spinner fa-spin"></i>
      <span style="margin-left:5px">loading</span>
    </h2>
  </div>
</template>

<script>
import Layout from '@/views/layout/Layout'
import { routerAddGuards } from '@/modules/router/hooks'
import { axiosAddInterceptors } from '@/modules/http/hooks'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
    title: 'App',
    titleTemplate: (process.env.VUE_APP_TITLE || 'Platform') + ' - %s'
  },
  components: {
    Layout
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      roles: 'auth/roles',
      perms: 'auth/perms'
    })
  },
  watch: {
    roles (roles) { this.$gates.setRoles(roles.length ? roles : []) },
    perms (perms) { this.$gates.setPermissions(perms.length ? perms : []) }
  },
  async mounted () {
    await this.$store.dispatch('auth/me')

    await routerAddGuards(this)
    await axiosAddInterceptors(this)

    this.loaded = true
  }
}
</script>

<style lang="css" src="./assets/css/bootstrap.min.css"></style>
<style lang="css" src="./assets/css/font-awesome.min.css"></style>
<style lang="css" src="./assets/css/adminlte.min.css"></style>
<style lang="css" src="./assets/css/skin-purple.min.css"></style>

<style lang="css" src="sweetalert2/dist/sweetalert2.min.css"></style>

<style lang="scss" src="./assets/scss/main.scss"></style>
