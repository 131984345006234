const routes = [
  {
    path: '/senders/categories',
    name: 'senders-categories-list',
    component: () => import(/* webpackChunkName: "page/senders/categories/list" */'@/views/pages/senders/categories/List'),
    meta: {
      permissions: 'sender_categories_read'
    }
  },
  {
    path: '/senders/categories/new',
    name: 'senders-categories-new',
    component: () => import(/* webpackChunkName: "page/senders/categories/new" */'@/views/pages/senders/categories/New'),
    meta: {
      permissions: 'sender_categories_write'
    }
  },
  {
    path: '/senders/categories/:id',
    name: 'senders-categories-single',
    component: () => import(/* webpackChunkName: "page/senders/categories/single" */'@/views/pages/senders/categories/Single'),
    meta: {
      permissions: 'sender_categories_read'
    }
  },
  {
    path: '/senders/categories/:id/edit',
    name: 'senders-categories-edit',
    component: () => import(/* webpackChunkName: "page/senders/categories/edit" */'@/views/pages/senders/categories/Edit'),
    meta: {
      permissions: 'sender_categories_write'
    }
  }
]

export default routes
