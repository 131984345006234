<template>
  <header class="main-header">
    <router-link class="logo" :to="{ name: 'index' }">
      <span class="logo-mini">
        <i class="fa fa-envelope fa-lg"></i>
      </span>
      <span class="logo-lg">
        <strong>Email Platform</strong>
      </span>
    </router-link>
    <nav class="navbar navbar-static-top" role="navigation">
      <a href="#" class="sidebar-toggle" role="button" @click.prevent="sidebarToggle">
        <span class="sr-only">Toggle Navigation</span>
      </a>
      <app-header-nav/>
    </nav>
  </header>
</template>

<script>
import AppHeaderNav from '@/views/layout/partials/header/HeaderNav'

export default {
  name: 'Header',
  components: {
    AppHeaderNav
  },
  methods: {
    sidebarToggle () {
      document.body.classList.toggle(
        window.innerWidth > 767
          ? 'sidebar-collapse'
          : 'sidebar-open'
      )
    }
  }
}
</script>

<style lang="scss">
.main-header {
  .logo {
    .logo-mini {
      font-size: 0.7em !important;
    }

    .logo-lg {
      font-size: 1em !important;
    }
  }
}
</style>
