<template>
  <tr :class="{ 'new': isNew }">
    <slot/>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'TableRow',
  props: {
    value: { type: Object, required: true }
  },
  data () {
    return {
      isNew: false
    }
  },
  created () {
    this.checkHighlightedId(this.value.id).then((value) => { this.isNew = value })
  },
  methods: {
    ...mapActions({
      checkHighlightedId: 'app/checkHighlightedId'
    })
  }
}
</script>

<style lang="scss">
.table tr.new {
  animation: bg-new-item 1.7s 0.3s 3 linear;
}
@keyframes bg-new-item {
  $color: rgba(0, 166, 90, 0.2);
  0% { background-color: inherit; }
  33% { background-color: $color; }
  66% { background-color: $color; }
}
</style>
