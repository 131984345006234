const routes = [
  {
    path: '/templates',
    name: 'templates-list',
    component: () => import(/* webpackChunkName: "page/templates/list" */'@/views/pages/templates/List'),
    meta: {
      permissions: 'templates_read'
    }
  },
  {
    path: '/templates/new',
    name: 'templates-new',
    component: () => import(/* webpackChunkName: "page/templates/new" */'@/views/pages/templates/New'),
    meta: {
      permissions: 'templates_write'
    }
  },
  {
    path: '/templates/:id',
    name: 'templates-single',
    component: () => import(/* webpackChunkName: "page/templates/single" */'@/views/pages/templates/Single'),
    meta: {
      permissions: 'templates_read'
    }
  },
  {
    path: '/templates/:id/edit',
    name: 'templates-edit',
    component: () => import(/* webpackChunkName: "page/templates/edit" */'@/views/pages/templates/Edit'),
    meta: {
      permissions: 'templates_write'
    }
  }
]

export default routes
