<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <ul class="sidebar-menu">
        <template v-for="(menu, m) in menus">
          <li class="header" :key="`menu-${m}`">{{ menu.header }}</li>

          <template v-for="(item, i) in menu.items">
            <component
              :is="`menu-item-${getType(item)}`"
              :key="`menu-${m}-item-${i}`"
              :params="item"
            />
          </template>
        </template>
      </ul>
    </section>
  </aside>
</template>

<script>
import menus from '@/modules/router/sidebar-menus'
import MenuItemParent from '@/views/layout/partials/sidebar/MenuItemParent'
import MenuItemLink from '@/views/layout/partials/sidebar/MenuItemLink'

export default {
  name: 'Sidebar',
  components: {
    MenuItemParent,
    MenuItemLink
  },
  data () {
    return {
      menus: []
    }
  },
  created () {
    this.menus = this.getMenuFiltered()
  },
  watch: {
    $route (to) { this.checkActiveParent(to.name) }
  },
  methods: {
    getType (item) {
      return item.children ? 'parent' : 'link'
    },
    getMenuFiltered () {
      const routeName = this.$route.name

      return menus.filter((menu) => {
        menu.items = menu.items.filter((item) => {
          if (!item.children) {
            return this.checkRoute(item.routeName)
          }

          item.children = item.children.filter((child) => {
            return this.checkRoute(child.routeName)
          })

          if (item.children.length <= 0) return false

          if (!item.activeNames) item.activeNames = []
          item.active = item.expanded = this.isParentActive(item, routeName)

          return true
        })

        return menu.items.length > 0
      })
    },
    checkRoute (name) {
      const route = this.findRoute(name)

      return (typeof route === 'undefined')
        ? false
        : this.checkPerms(route?.meta?.permissions || '')
    },
    findRoute (name) {
      return this.$router.resolve({ name: name })?.route
    },
    checkPerms (perms) {
      return !perms || this.$gates.hasAnyPermission(perms)
    },
    checkActiveParent (routeName) {
      this.menus.forEach((menu) => {
        menu.items.forEach((item) => {
          if (item.children) item.active = item.expanded = this.isParentActive(item, routeName)
        })
      })
    },
    isParentActive (item, routeName) {
      return item.activeNames.some((n) => routeName.indexOf(n) === 0)
    }
  }
}
</script>
