const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "page/auth/login" */'@/views/pages/auth/Login.vue'),
    meta: { layout: 'guest', guest: true }
  }
]

export default routes
