<template>
  <div class="navbar-custom-menu">
    <ul class="nav navbar-nav">
      <li class="dropdown user user-menu" :class="{ 'open' : userMenuOpened }" v-if="user">
        <a href="#" @click.prevent.stop="userMenuToggle">
          <span :title="user.name || 'NOT_FOUND'">
            <i class="fa fa-lg fa-user-circle-o visible-xs" style="margin:0;line-height:inherit;"></i>
            <span class="hidden-xs">{{ user.name || 'NOT_FOUND' }}</span>
          </span>
        </a>
        <ul class="dropdown-menu" @click.stop>
          <li class="user-header">
            <p>
              <span :title="user.name || 'NOT_FOUND'">{{ user.name || 'NOT_FOUND' }}</span>
              <small>Roles: {{ (roles || []).join(', ') }}</small>
            </p>
          </li>
          <li class="user-footer">
            <div class="pull-left">
              <btn color="default" @click.prevent.native="goProfile" flat text>Profile</btn>
            </div>
            <div class="pull-right">
              <btn color="default" :disabled="outDisabled" @click.prevent.native="logOut" flat text>Sign Out</btn>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import Btn from '@/views/components/simple/Btn'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HeaderNav',
  components: {
    Btn
  },
  data () {
    return {
      outDisabled: false,
      userMenuOpened: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      roles: 'auth/roles'
    })
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    goProfile () {
      if (!this.user) return

      this.$router.push({ name: 'profile' })
      this.userMenuClose()
    },
    async logOut () {
      if (this.outDisabled) return

      this.outDisabled = true

      await this.signOut()
        .then(() => {
          this.$router.replace({ name: 'login' })
        })
        .catch(() => {
          this.outDisabled = false
        })
    },
    userMenuToggle () {
      this.userMenuOpened = !this.userMenuOpened
      this.userMenuOpened
        ? document.addEventListener('click', this.userMenuClose)
        : document.removeEventListener('click', this.userMenuClose)
    },
    userMenuClose () {
      this.userMenuOpened = false
      document.removeEventListener('click', this.userMenuClose)
    }
  }
}
</script>

<style lang="scss">
.main-header {
  .dropdown.user-menu {
    max-width: 280px;
    white-space: nowrap;

    .dropdown-toggle {
      max-width: 280px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul li.user-header {
      height: auto;
    }
  }
}
</style>
