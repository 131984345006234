const routes = [
  {
    path: '/subjects',
    name: 'subjects-list',
    component: () => import(/* webpackChunkName: "page/subjects/list" */'@/views/pages/subjects/List'),
    meta: {
      permissions: 'subjects_read'
    }
  },
  {
    path: '/subjects/new',
    name: 'subjects-new',
    component: () => import(/* webpackChunkName: "page/subjects/new" */'@/views/pages/subjects/New'),
    meta: {
      permissions: 'subjects_write'
    }
  },
  {
    path: '/subjects/:id',
    name: 'subjects-single',
    component: () => import(/* webpackChunkName: "page/subjects/single" */'@/views/pages/subjects/Single'),
    meta: {
      permissions: 'subjects_read'
    }
  },
  {
    path: '/subjects/:id/edit',
    name: 'subjects-edit',
    component: () => import(/* webpackChunkName: "page/subjects/edit" */'@/views/pages/subjects/Edit'),
    meta: {
      permissions: 'subjects_write'
    }
  }
]

export default routes
