<template>
  <span class="app-badge label" :class="bgColor" :title="title">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    title: { type: String, default: null },
    color: { type: String, default: 'gray' }
  },
  computed: {
    bgColor () { return 'bg-' + this.color }
  }
}
</script>

<style lang="scss">
.app-badge {
  margin-left: 2px;
  margin-right: 2px;
  &.label {
    padding: .3em .6em;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
</style>
