const map = function (object, callable, context) {
  context = context || this
  const result = {}

  Object.keys(object).forEach(function (key) {
    result[key] = callable.call(context, object[key], key, object)
  })

  return result
}

export default {
  map
}
