const routes = [
  { path: '/', name: 'index', redirect: '/dashboard' },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "page/general/not-found" */'@/views/pages/general/NotFound'),
    meta: { layout: 'guest' }
  }
]

export default routes
