const routes = [
  {
    path: '/apis',
    name: 'apis-list',
    component: () => import(/* webpackChunkName: "page/apis/list" */'@/views/pages/apis/List'),
    meta: {
      permissions: 'apis_read'
    }
  },
  {
    path: '/apis/new',
    name: 'apis-new',
    component: () => import(/* webpackChunkName: "page/apis/new" */'@/views/pages/apis/New'),
    meta: {
      permissions: 'apis_write'
    }
  },
  {
    path: '/apis/:id',
    name: 'apis-single',
    component: () => import(/* webpackChunkName: "page/apis/single" */'@/views/pages/apis/Single'),
    meta: {
      permissions: 'apis_read'
    }
  },
  {
    path: '/apis/:id/edit',
    name: 'apis-edit',
    component: () => import(/* webpackChunkName: "page/apis/edit" */'@/views/pages/apis/Edit'),
    meta: {
      permissions: 'apis_write'
    }
  }
]

export default routes
