const routes = [
  {
    path: '/campaigns',
    name: 'campaigns-list',
    component: () => import(/* webpackChunkName: "page/campaigns/list" */'@/views/pages/campaigns/List'),
    meta: {
      permissions: 'campaigns_read'
    }
  },
  {
    path: '/campaigns/new',
    name: 'campaigns-new',
    component: () => import(/* webpackChunkName: "page/campaigns/new" */'@/views/pages/campaigns/New'),
    meta: {
      permissions: 'campaigns_write'
    }
  },
  {
    path: '/campaigns/:id',
    name: 'campaigns-single',
    component: () => import(/* webpackChunkName: "page/campaigns/single" */'@/views/pages/campaigns/Single'),
    meta: {
      permissions: 'campaigns_read'
    }
  },
  {
    path: '/campaigns/:id/edit',
    name: 'campaigns-edit',
    component: () => import(/* webpackChunkName: "page/campaigns/edit" */'@/views/pages/campaigns/Edit'),
    meta: {
      permissions: 'campaigns_write'
    }
  },
  {
    path: '/campaigns/:id/duplicate',
    name: 'campaigns-duplicate',
    component: () => import(/* webpackChunkName: "page/campaigns/new" */'@/views/pages/campaigns/New'),
    meta: {
      permissions: 'campaigns_write'
    }
  }
]

export default routes
