<template>
  <div class="content-wrapper">
    <section class="content-header">
      <h1>
        <portal-target name="page-top-title" tag="span"/>
        <portal-target name="page-top-right" class="pull-right"/>
      </h1>
    </section>
    <section class="content">
      <alerts/>
      <router-view/>
    </section>
  </div>
</template>

<script>
import Alerts from '@/views/layout/partials/Alerts.vue'

export default {
  name: 'Content',
  components: {
    Alerts
  }
}
</script>
