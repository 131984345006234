const lowerFirst = (val) => val.charAt(0).toLowerCase() + val.slice(1)
const upperFirst = (val) => val.charAt(0).toUpperCase() + val.slice(1)
const titleCase = (val) => {
  return val
    .toLowerCase()
    .replace(/[-_]/g, ' ')
    .replace(/\w\S*/g, upperFirst)
}
const camelCase = (val) => {
  return lowerFirst(titleCase(val))
}

export default {
  lowerFirst,
  upperFirst,
  titleCase,
  camelCase
}
