<template>
  <router-link tag="li" :to="{ name: params.routeName }">
    <a>
      <i class='fa' :class="params.icon"></i>
      <span>{{ params.title }}</span>
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'MenuItemLink',
  props: {
    params: { type: Object }
  }
}
</script>
