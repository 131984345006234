const routes = [
  {
    path: '/feedbacks',
    name: 'feedbacks-list',
    component: () => import(/* webpackChunkName: "page/feedbacks/list" */'@/views/pages/feedbacks/List'),
    meta: {
      permissions: 'feedbacks_read'
    }
  },
  {
    path: '/feedbacks/new',
    name: 'feedbacks-new',
    component: () => import(/* webpackChunkName: "page/feedbacks/new" */'@/views/pages/feedbacks/New'),
    meta: {
      permissions: 'feedbacks_write'
    }
  },
  {
    path: '/feedbacks/:id',
    name: 'feedbacks-single',
    component: () => import(/* webpackChunkName: "page/feedbacks/single" */'@/views/pages/feedbacks/Single'),
    meta: {
      permissions: 'feedbacks_read'
    }
  },
  {
    path: '/feedbacks/:id/edit',
    name: 'feedbacks-edit',
    component: () => import(/* webpackChunkName: "page/feedbacks/edit" */'@/views/pages/feedbacks/Edit'),
    meta: {
      permissions: 'feedbacks_write'
    }
  }
]

export default routes
