<template>
  <div :class="classes">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Column',
  props: {
    xs: { type: Number, default: 12 },
    sm: { type: Number, default: undefined },
    md: { type: Number, default: undefined },
    lg: { type: Number, default: undefined }
  },
  computed: {
    classes () {
      const classes = ['col-xs-' + this.xs]

      if (this.sm) classes.push('col-sm-' + this.sm)
      if (this.md) classes.push('col-md-' + this.md)
      if (this.lg) classes.push('col-lg-' + this.lg)

      return classes.join(' ')
    }
  }
}
</script>
