const initPolyfills = () => {
  if (!Array.isArray) {
    // noinspection JSValidateTypes
    Array.isArray = function (arg) {
      return Object.prototype.toString.call(arg) === '[object Array]'
    }
  }
  if (!Object.isObject) {
    Object.isObject = function (arg) {
      return arg !== null && arg?.constructor === Object
    }
  }
}

export default initPolyfills
