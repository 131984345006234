const routes = [
  {
    path: '/offers',
    name: 'offers-list',
    component: () => import(/* webpackChunkName: "page/offers/list" */'@/views/pages/offers/List'),
    meta: {
      permissions: 'offers_read'
    }
  },
  {
    path: '/offers/new',
    name: 'offers-new',
    component: () => import(/* webpackChunkName: "page/offers/new" */'@/views/pages/offers/New'),
    meta: {
      permissions: 'offers_write'
    }
  },
  {
    path: '/offers/:id',
    name: 'offers-single',
    component: () => import(/* webpackChunkName: "page/offers/single" */'@/views/pages/offers/Single'),
    meta: {
      permissions: 'offers_read'
    }
  },
  {
    path: '/offers/:id/edit',
    name: 'offers-edit',
    component: () => import(/* webpackChunkName: "page/offers/edit" */'@/views/pages/offers/Edit'),
    meta: {
      permissions: 'offers_write'
    }
  }
]

export default routes
