<template>
  <column :xs="xs" :sm="sm" :md="md" :lg="lg">
    <slot/>
  </column>
</template>

<script>
export default {
  name: 'ColumnInput',
  props: {
    xs: { type: Number, default: 12 },
    sm: { type: Number, default: 6 },
    md: { type: Number, default: 4 },
    lg: { type: Number, default: undefined }
  }
}
</script>
