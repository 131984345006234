import Vue from 'vue'
import SlideUpDown from 'vue-slide-up-down'
import Box from '@/views/components/layout/Box'
import BoxSection from '@/views/components/layout/BoxSection'
import Row from '@/views/components/layout/Row'
import Column from '@/views/components/layout/Column'
import ColumnInfo from '@/views/components/layout/ColumnInfo'
import ColumnInput from '@/views/components/layout/ColumnInput'
import TableRow from '@/views/components/layout/TableRow'

const initComponents = () => {
  // libs
  Vue.component('SlideUpDown', SlideUpDown)
  // layout
  Vue.component('Box', Box)
  Vue.component('BoxSection', BoxSection)
  Vue.component('Row', Row)
  Vue.component('Column', Column)
  Vue.component('ColumnInfo', ColumnInfo)
  Vue.component('ColumnInput', ColumnInput)
  Vue.component('TableRow', TableRow)
}

export default initComponents
