function interceptRequest (app) {
  // silence is golden
}
function interceptResponse (app) {
  app.$http.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error?.response?.status === 401) {
        await app.$store.dispatch('auth/clean')
        await app.$router.push({ name: 'login', query: { nextUrl: app.$route.fullPath } })
      }

      return Promise.reject(error)
    })
}
export const axiosAddInterceptors = async (app) => {
  interceptRequest(app)
  interceptResponse(app)
}
