<template>
  <span v-if="text">
    <template v-if="icon">
      <span class="sr-only"><slot/></span>
      <span class="hidden-xs"><slot/></span>
      <span class="visible-xs"><i class="fa" :class="icon"/></span>
    </template>

    <template v-else>
      <slot/>
    </template>
  </span>

  <span v-else>
    <i class="fa" :class="icon"/>
    <span class="sr-only">
      <slot/>
    </span>
  </span>
</template>

<script>
export default {
  name: 'BtnInner',
  props: {
    icon: { type: String, default: null },
    text: { type: Boolean, default: false }
  }
}
</script>
