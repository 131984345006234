import Vue from 'vue'
import initPolyfills from '@/modules/bootstrap/polyfills'
import initPlugins from '@/modules/bootstrap/plugins'
import initComponents from '@/modules/bootstrap/components'
import initAxios from '@/modules/http'

const bootstrap = () => {
  Vue.config.productionTip = (process.env.NODE_ENV !== 'development')

  initPolyfills()
  initPlugins()
  initComponents()
  initAxios()
}

export default bootstrap
