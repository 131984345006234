import Vue from 'vue'
import Str from '@/modules/helpers/str'
import Obj from '@/modules/helpers/obj'
import FormTransform from '@/modules/helpers/formTransform'

const helpers = {
  install () {
    // noinspection JSUnusedGlobalSymbols
    Vue.helpers = Vue.prototype.$helpers = {
      Str,
      Obj,
      FormTransform
    }
  }
}

export default helpers
