const menus = [
  {
    header: '',
    items: [
      {
        title: 'Dashboard',
        icon: 'fa-dashboard',
        routeName: 'dashboard'
      },
      {
        title: 'Campaigns',
        icon: 'fa-tasks',
        activeNames: ['campaigns'],
        children: [
          { title: 'Labels', routeName: 'campaigns-labels-list' },
          { title: 'Campaigns', routeName: 'campaigns-list' },
          { title: 'Add New', routeName: 'campaigns-new' }
        ]
      },
      {
        title: 'Feedbacks',
        icon: 'fa-bullhorn',
        activeNames: ['feedbacks'],
        children: [
          { title: 'Feedbacks', routeName: 'feedbacks-list' },
          { title: 'Add New', routeName: 'feedbacks-new' }
        ]
      },
      {
        title: 'CategoryGroups',
        icon: 'fa-list-alt',
        activeNames: ['categoryGroups'],
        children: [
          { title: 'Groups', routeName: 'categoryGroups-list' },
          { title: 'Add New', routeName: 'categoryGroups-new' }
        ]
      },
      {
        title: 'Lists',
        icon: 'fa-list',
        activeNames: ['contactLists'],
        children: [
          { title: 'Categories', routeName: 'contactLists-categories-list' },
          { title: 'Lists', routeName: 'contactLists-list' },
          { title: 'Add New', routeName: 'contactLists-new' }
        ]
      },
      {
        title: 'Blacklist',
        icon: 'fa-ban',
        activeNames: ['blacklists'],
        children: [
          { title: 'Lists', routeName: 'blacklists-list' },
          { title: 'Add New', routeName: 'blacklists-new' }
        ]
      },
      {
        title: 'Badwords',
        icon: 'fa-exclamation-circle',
        activeNames: ['badwords'],
        children: [
          { title: 'Categories', routeName: 'badwords-categories-list' },
          { title: 'Badwords', routeName: 'badwords-list' },
          { title: 'Add New', routeName: 'badwords-new' }
        ]
      },
      {
        title: 'Templates',
        icon: 'fa-file-text',
        activeNames: ['templates'],
        children: [
          { title: 'Categories', routeName: 'templates-categories-list' },
          { title: 'Templates', routeName: 'templates-list' },
          { title: 'Add New', routeName: 'templates-new' }
        ]
      },
      // { !! DISABLED FOR NOW !!
      //   title: 'Macros',
      //   icon: 'fa-code',
      //   activeNames: ['macros'],
      //   children: [
      //     { title: 'Macros', routeName: 'macros-list' },
      //     { title: 'Add New', routeName: 'macros-new' }
      //   ]
      // },
      {
        title: 'Offers',
        icon: 'fa-link',
        activeNames: ['offers'],
        children: [
          { title: 'Offers', routeName: 'offers-list' },
          { title: 'Add New', routeName: 'offers-new' }
        ]
      },
      {
        title: 'Domains',
        icon: 'fa-globe',
        activeNames: ['domains'],
        children: [
          { title: 'Domains', routeName: 'domains-list' },
          { title: 'Add New', routeName: 'domains-new' }
        ]
      },
      {
        title: 'Senders',
        icon: 'fa-at',
        activeNames: ['senders'],
        children: [
          { title: 'Categories', routeName: 'senders-categories-list' },
          { title: 'Senders', routeName: 'senders-list' },
          { title: 'Add New', routeName: 'senders-new' }
        ]
      },
      {
        title: 'Subjects',
        icon: 'fa-book',
        activeNames: ['subjects'],
        children: [
          { title: 'Categories', routeName: 'subjects-categories-list' },
          { title: 'Subjects', routeName: 'subjects-list' },
          { title: 'Add New', routeName: 'subjects-new' }
        ]
      }
    ]
  },
  {
    header: 'SYSTEM',
    items: [
      {
        title: 'Companies',
        icon: 'fa-briefcase',
        activeNames: ['companies'],
        children: [
          { title: 'Companies', routeName: 'companies-list' }
        ]
      },
      {
        title: 'Users',
        icon: 'fa-users',
        activeNames: ['users'],
        children: [
          { title: 'Users', routeName: 'users-list' },
          { title: 'Add New', routeName: 'users-new' }
        ]
      },
      {
        title: 'Apis',
        icon: 'fa-external-link-square',
        activeNames: ['apis'],
        children: [
          { title: 'Providers', routeName: 'apis-providers-list' },
          { title: 'Apis', routeName: 'apis-list' },
          { title: 'Add New', routeName: 'apis-new' }
        ]
      }
    ]
  }
]

export default menus
