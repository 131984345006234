<template>
  <transition appear>
    <component :is="layoutName" v-cloak/>
  </transition>
</template>

<script>
import Default from '@/views/layout/Default'
import Guest from '@/views/layout/Guest'

export default {
  name: 'Layout',
  components: {
    Default,
    Guest
  },
  computed: {
    layoutName () {
      const layoutDefault = this.$route?.matched?.length ? 'default' : 'guest'

      return (this.$route.meta.layout || layoutDefault)
    }
  }
}
</script>
