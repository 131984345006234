<template>
  <router-link :to="route" :class="classes" v-if="route">
    <btn-inner :icon="icon" :text="text">
      <slot/>
    </btn-inner>
  </router-link>

  <a :href="href" :class="classes" v-else-if="href">
    <btn-inner :icon="icon" :text="text">
      <slot/>
    </btn-inner>
  </a>

  <button :class="classes" v-else>
    <btn-inner :icon="icon" :text="text">
      <slot/>
    </btn-inner>
  </button>
</template>

<script>
import BtnInner from './BtnInner'

export default {
  name: 'Btn',
  components: {
    BtnInner
  },
  props: {
    route: { type: Object, default: undefined },
    href: { type: String, default: undefined },
    size: { type: String, default: 'sm' },
    color: { type: String, default: 'primary' },
    icon: { type: String, default: null },
    flat: { type: Boolean, default: false },
    text: { type: Boolean, default: false }
  },
  computed: {
    classes () {
      let classes = `btn btn-${this.size} btn-${this.color}`

      if (this.flat) classes += ' btn-flat'

      return classes
    }
  }
}
</script>
