<template>
  <div class="wrapper">
    <app-header/>
    <app-sidebar/>
    <app-content/>
    <app-notifications/>
    <app-footer/>
  </div>
</template>

<script>
import AppHeader from '@/views/layout/partials/header/Header'
import AppSidebar from '@/views/layout/partials/sidebar/Sidebar'
import AppContent from '@/views/layout/partials/Content'
import AppNotifications from '@/views/layout/partials/Notifications'
import AppFooter from '@/views/layout/partials/Footer'

export default {
  name: 'LayoutDefault',
  components: {
    AppHeader,
    AppSidebar,
    AppContent,
    AppNotifications,
    AppFooter
  }
}
</script>
