import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const apiUrl = (process.env.VUE_APP_API_URL || '').replace(/\/+$/, '')
const apiVer = (process.env.VUE_APP_API_VERSION || '').replace(/\/+$/, '')

axios.defaults.baseURL = `${apiUrl}/${apiVer}/`
axios.defaults.withCredentials = true
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const initAxios = () => {
  Vue.use(VueAxios, axios)
}

export default initAxios
